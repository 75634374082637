<template>
    <div>
        <MainInfoBlock />
        <AboutUsBlock id="about_us"/>
        <ReasonsToChooseBlock />
        <OurProjectsBlock id="projects"/>
        <StageBlock id="production"/>
        <AdditionalInfoBlock />
        <ContactsBlock id="contacts"/>
    </div>
</template>

<script>
import MainInfoBlock from "./MainInfoBlock.vue"
import AboutUsBlock from './AboutUsBlock.vue'
import ReasonsToChooseBlock from "./ReasonsToChooseBlock.vue"
import OurProjectsBlock from "./OurProjectsBlock.vue"
import StageBlock from './StageBlock.vue'
import ContactsBlock from '../UI/ContactsBlock.vue'
import AdditionalInfoBlock from './AdditionalInfoBlock.vue'

export default {
    components: {
        MainInfoBlock,
        AboutUsBlock,
        ReasonsToChooseBlock,
        OurProjectsBlock,
        AdditionalInfoBlock,
        ContactsBlock,
        StageBlock,
    }
}
</script>