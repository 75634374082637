<template>
  <div class="min-100-vh">
    <div class="background-main">
      <v-img
        src="@/assets/images/background-image_main.png"
        class="image"
        alt="background-img"
      />
      <div class="content">
        <v-container class="px-0">
          <v-row
            align="center"
            justify="center"
            justify-sm="start"
            class="content-row"
            :class="{ 'content-row-mobile': $vuetify.breakpoint.smAndDown }"
          >
            <v-col class="col-11 col-md-12">
              <div
                class="font-48 font-weight-600 text-uppercase"
                :class="{ 'font-32': $vuetify.breakpoint.smAndDown }"
              >
                <span class="text-color-orange">Rietli Immobilien AG</span> -
                Ihrem Partner für bezugsfertige Häuser!
              </div>
              <div
                class="my-10 font-20"
                :class="{ 'font-16': $vuetify.breakpoint.smAndDown }"
                :style="
                  !$vuetify.breakpoint.smAndDown ? 'margin-right: 250px' : ''
                "
              >
                Sparen Sie wertvolle Zeit und Aufwand beim Bau und der
                Renovierung Ihres neuen Eigenheims. Mit unseren bezugsfertigen
                Häusern können Sie bereits in wenigen Monaten einziehen! Wir
                bieten Ihnen die perfekte Lösung für ein komfortables und
                stressfreies Wohnen.
              </div>
              <div>
                <v-btn
                  color="#F39300"
                  class="btn-classic"
                  :width="!$vuetify.breakpoint.smAndDown ? '304px' : '100%'"
                  outlined
                  @click="scrollToId('projects')"
                >
                  Katalog ansehen
                </v-btn>
              </div>
            </v-col>
            <MovedArrow />
          </v-row>
        </v-container>
      </div>
    </div>
    <Header />
  </div>
</template>

<script>
import Header from "../UI/Header.vue";
import MovedArrow from '../UI/MovedArrow.vue'

export default {
  components: {
    Header,
    MovedArrow,
  },
  methods: {
    scrollToId(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
  mounted() {
    if (localStorage.moveTo) {
      document.getElementById(localStorage.moveTo).scrollIntoView({
        behavior: "smooth",
      });
      localStorage.removeItem("moveTo");
    } else {
      window.scrollTo(0, 0);
    }
  },
};
</script>

<style scoped lang="scss">
.background-main {
  position: relative;
  .image {
    width: 100vw !important;
    height: 100vh !important;
    object-fit: cover !important;
  }
  .image::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  }
  .content {
    position: absolute;
    top: 104px;
    left: 0;
    right: 0;
    bottom: 0px;
    color: #fff;
    &-row {
      height: calc(100vh - 104px) !important;
      &-mobile {
        height: calc(100vh - 204px) !important;
      }
    }
  }
}
.btn-classic {
  width: 304px;
  height: 48px !important;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;

  border: 2px solid #f39300;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  transition: 0.3s ease-out;

  border-radius: 5px;
  &:hover {
    background: #f39300;
    color: #efefef !important;
  }
}
</style>