<template>
    <MainPageComponent />
</template>

<script>
import MainPageComponent from '@/components/MainPage/MainPageComponent.vue'

export default {
    components: {
        MainPageComponent
    }
}
</script>