<template>
  <div class="py-10" style="background: #f2f2f2">
    <v-container>
      <v-row class="mx-2 mx-sm-0">
        <v-col class="col-12 col-md-6">
          <v-row class="text-color-orange font-36 font-weight-500"> 01 </v-row>
          <v-row
            class="text-color-black font-36 align-center font-weight-600 position-relative title-row"
          >
            <div class="f-title">Über uns</div>
            <div class="sec-title">Über uns</div>
          </v-row>
          <v-row class="text-color-black font-20 font-weight-500 d-block mt-0" :class="{'font-16 mt-15' : $vuetify.breakpoint.smAndDown}">
            <span class="font-weight-600">Rietli Immobilien AG</span> - führend
            in der Herstellung von modularen Gebäuden. <br /><br />
            Rietli Immobilien AG ist ein hochtechnologisches Unternehmen, das
            sich auf die Herstellung innovativer modularer Gebäude spezialisiert
            hat. Gegründet im Jahr 2012, hat sich das Unternehmen als führender
            Hersteller in dieser Branche durch seine unübertroffene Qualität,
            fortschrittliche Technologie und individuelle Kundenbetreuung
            etabliert. <br /><br />
            <v-expand-transition>
              <div v-if="display">
                Rietli Immobilien AG ist stolz darauf, Kunden weltweit zu
                bedienen und ihre Bedürfnisse im Bereich modularer Gebäude zu
                erfüllen. Mit einem Schwerpunkt auf Qualität, Innovation und
                Kundenzufriedenheit bleibt das Unternehmen an der Spitze der
                Branche und setzt neue Standards für modulares Bauen.<br /><br />
              </div>
            </v-expand-transition>
          </v-row>
          <v-row>
            <v-btn
              @click="checkDisplay()"
              class="btn-anim text-capitalize"
              color="#1B1B1B"
              outlined
            >
              <div class="icon">
                <v-icon color="#f2f2f2">mdi-arrow-right</v-icon>
              </div>
              <div class="content-text">{{ text }}</div>
            </v-btn>
          </v-row>
        </v-col>
        <v-col class="col-12 col-md-6 mt-10 mt-sm-0">
          <v-row justify="center" justify-sm="end">
            <img
              :width="!$vuetify.breakpoint.mdAndDown ? '520px' : '100%'"
              :height="!$vuetify.breakpoint.mdAndDown ? '480px' : '345px'"
              src="@/assets/images/about-us-img.png"
            />
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-10">
        <v-col class="col-12 col-md-6 col-lg-3">
          <v-row no-gutters align="center">
            <div class="font-51 font-weight-600 text-color-orange"
            :class="{
                'w-100 justify-center d-flex': $vuetify.breakpoint.smAndDown,
              }">10+</div>
            <div
              class="text-color-black font-20 font-weight-500 ml-1"
              style="line-height: 28px"
              :class="{
                'w-100 justify-center d-flex text-center': $vuetify.breakpoint.smAndDown,
              }"
            >
              Jahren <br />
              auf dem Markt
            </div>
          </v-row>
        </v-col>
        <v-col class="col-12 col-md-6 col-lg-3">
          <v-row no-gutters align="center">
            <div
              class="font-51 font-weight-600 text-color-orange"
              :class="{
                'w-100 justify-center d-flex': $vuetify.breakpoint.smAndDown,
              }"
            >
              6
            </div>
            <div
              class="text-color-black font-20 font-weight-500 ml-1"
              style="line-height: 28px"
              :class="{
                'w-100 justify-center d-flex': $vuetify.breakpoint.smAndDown,
              }"
            >
              Einzigartige Projekte
            </div>
          </v-row>
        </v-col>
        <v-col class="col-12 col-md-6 col-lg-3">
          <v-row no-gutters align="center">
            <div class="font-51 font-weight-600 text-color-orange" :class="{
                'w-100 justify-center d-flex': $vuetify.breakpoint.smAndDown,
              }">80+</div>
            <div
              class="text-color-black font-20 font-weight-500 ml-1"
              style="line-height: 28px"
              :class="{
                'w-100 justify-center d-flex text-center': $vuetify.breakpoint.smAndDown,
              }"
            >
              Durchgeführte<br />Projekte
            </div>
          </v-row>
        </v-col>
        <v-col class="col-12 col-md-6 col-lg-3">
          <v-row no-gutters align="center">
            <div
              class="font-51 font-weight-600 text-color-orange"
              style="margin-right: 3px"
              :class="{
                'w-100 justify-center d-flex': $vuetify.breakpoint.smAndDown,
              }"
            >
              100
            </div>
            <div
              class="text-color-black font-20 font-weight-500"
              style="line-height: 28px"
              :class="{
                'w-100 justify-center d-flex': $vuetify.breakpoint.smAndDown,
              }"
            >
              Menschen im Team
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    text: "mehr lesen",
    display: false,
  }),
  methods: {
    checkDisplay() {
      this.display = !this.display;

      if (this.display) {
        this.text = "ausblenden";
      } else {
        this.text = "mehr lesen";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.btn-anim {
  width: 192px;
  height: 48px !important;
  background: #f2f2f2;
  border: 2px solid #1b1b1b;
  border-radius: 5px;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #1b1b1b;
  overflow: hidden;
  transition: all 0.2s ease-out;

  .icon {
    margin-left: -60px;
    transition: all 0.2s ease-out 0s;
  }
  .content-text {
    margin-left: 30px;
    transition: all 0.2s ease-out 0.1s;
  }
  &:hover {
    background: #1b1b1b;
  }
  &:hover .icon {
    margin-left: 145px;
    transition: all 0.2s ease-out 0.1s;
  }
  &:hover .content-text {
    margin-top: 100px;
    transition: all 0.2s ease-out 0s;
  }
}
#text-hidden {
  visibility: hidden;
  transition: all 0.2s ease-out;
}
</style>